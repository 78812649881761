<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
        opt: {
        search: [
          {
            key: "date",
            label: "起止日期",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          },
          {
            key: "validateType",
            label: "验证方式",
            type: "select",
            opt: {
              list: [
                {
                  label: "手机号+密码",
                  value: "1"
                },
                {
                  label: "手机号+验证码",
                  value: "2"
                }
              ]
            }
          }
        ],
        columns: [
          { label: "序号", key: "sequenceNumber"},
          { label: "验证方式", key: "validationType" },
          { label: "开锁人", key: "unlockUserName" },
          { label: "手机号码", key: "phoneNoStr" },
          { label: "房源", key: "RoomName" },
          { label: "借用时间", key: "borrowTime" },
          // { label: "开锁时间", key: "unlockDate" },
          { label: "退还时间", key: "returnTime",type:"diff-color",
          opt:{
            style:'color:red',//设置颜色
            key:'colorFlag'//改变颜色标识
            }
           }
        ]
      },
    };
  },
  created() {
    console.log("doorOpeningLog created!!");
    
  },
  activated() {
    console.log("doorOpeningLog activated!!");
    // this.$refs.appList.onRefresh();
  },
  mounted(){
     
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        gatewayId: this.$route.params.id,
        beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        validateType: opt.searchForm["validateType"]
          ? opt.searchForm["validateType"]
          : ""
      };
      this.post("/device-service/L2/pageQuery/borrowRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.validationType = ["手机号+密码", "手机号+验证码"][
            item.validationType - 1
          ];
          item.sequenceNumber=opt.limit*(opt.skip-1)+i+1;
          item.borrowTime =
            item.borrowTime == null ? "" : this.format(item.borrowTime);
          // item.unlockDate =
          //   item.unlockDate == null ? "" : this.format(item.unlockDate);
          item.returnTime =
            item.returnTime == null ? "" : this.format(item.returnTime);
          item.RoomName =
            (item.apartmentName?item.apartmentName:'') + (item.buildNo?(item.buildNo + "栋"):'') + (item.roomNo? (item.roomNo+ "房"):'');
            if (this.cache.getLS("userInfo")["roleType"] != "8") {
            item.phoneNoStr=item.phoneNo
              ? item.phoneNo.substring(0, 3) +
                "****" +
                item.phoneNo.substring(7, item.phoneNo.length)
              : "";
            }
            else{
              item.phoneNoStr=item.phoneNo
            }
        }
        opt.cb(res.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>