<template>
  <div class="table-box">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        search: [
           {
            key: "date",
            label: "起止日期",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          },
        ],
        columns: [
          { label: "序号", key: "sequenceNumber", width: 80 },
          // { label: "序号", key: "mac", width: 80 },
          { label: "开门禁方式", key: "unLockMethodStr" },
          { label: "开锁人", key: "unLockUser" },
          { label: "手机号码", key: "unLockUserPhone" },
          { label: "房间信息", key: "roomInfo" },
          { label: "开门禁时间", key: "createdDate" },
          { label: "开门禁结果", key: "unlockStateStr" ,type:"diff-color",
          opt:{
            style:'color:red',//设置颜色
            key:'colorFlag'//改变颜色标识
          }},
        ]
      },
    };
  },
  created() {
    console.log("individualLandlord created!!");
    
  },
  activated() {
    console.log("individualLandlord activated!!");
     this.row = this.cache.get("lockInfo");
    //  this.$refs.appList.onRefresh();
  },
  mounted(){
   
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        gatewayId: this.$route.params.id,
        beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        deviceType: this.$route.query.type,
      };
      this.post("/device-service/L2plus/queryOpenRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.unLockMethodStr = [
            "未知",
            "在线蓝牙开锁",
            "机械钥匙开锁",
            "人脸识别开锁",
            "离线蓝牙开锁",
            "远程开锁",
            "L2公钥开锁",
            "L2+密码开锁",
            "L2+蓝牙开锁",
            "IC卡开锁",
            "密码开锁",
            "W8上报特殊开锁记录",
            "L1开门禁",
            "L3开门禁",
            '',
            'W8M人脸识别'
          ][
            item.unLockMethod
          ];
          if(item.unLockMethod == 31) {
            item.unLockMethodStr = '扫码开门禁';
          }
          item.roomInfo=item.roomInfo?item.roomInfo:'--';
          item.unLockUserPhone=item.unLockUserPhone?item.unLockUserPhone:'--';
          //发钥匙时填入名字就会有开锁人
          item.unLockUser=item.unLockUser?item.unLockUser:'--';
          console.log(item.unLockMethod)
          if (item.unLockMethodStr == 'W8M人脸识别') {
            item.unlockStateStr = ['开门禁成功','开门禁失败'][item.unLockState - 1]
          }else {
            item.unlockStateStr = ["等待开锁", "开锁成功","开锁失败","开锁失败 (重复操作)","","","开锁失败 (设备未激活)"][item.unLockState];
          }
          
            // item.colorFlag=false;
            // if(i%2==0){
            //   item.colorFlag=true;
            // }
        }
        console.log(res.data)
        opt.cb(res.data);
      });
    },
  }
};
</script>
<style lang="scss">
.table-box{
  height: 620px;
}
</style>