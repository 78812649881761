<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <el-row class="info">
      <el-col :span="24">
        <span class="txt">安装位置: {{location ? location : '--'}}</span>
        <span class="txt">MAC: {{mac}}</span>
        <span class="txt">IP: {{roomIP}}</span>
        <span class="txt">设备状态: {{stateStr}}</span>
        <span class="txt">设备端软件版本: {{firmwareVersion}}</span>
      </el-col>
    </el-row>
     <ul class="tab-ul">
      <li :class="{'ative':currentTab=='electronicAccessControlRecord'}" @click="toggleTab('electronicAccessControlRecord')">电子门禁记录</li>
      <li :class="{'ative':currentTab=='publicKeyBorrowingRecord'}" @click="toggleTab('publicKeyBorrowingRecord')">公钥借用记录</li>
    </ul>
    <keep-alive>
    <electronicAccessControlRecord :is="currentTab"></electronicAccessControlRecord>
    </keep-alive>
  </div>
</template>
<script>
 import electronicAccessControlRecord from './electronicAccessControlRecord';
  import publicKeyBorrowingRecord from './publicKeyBorrowingRecord';
export default {
   components: { 
      electronicAccessControlRecord,
      publicKeyBorrowingRecord,
    },
  data() {
    let _this = this;
    return {
      title: "查看记录",
      currentTab: 'electronicAccessControlRecord',
      id: "",
      apartmentName: "",
      location: "",
      mac: "",
      roomIP: "",
      roomstatus: "",
      firmwareVersion: "",
      stateStr: "",
      type: "",
    };
  },
  activated() {
    console.log("lock-log activated!!");
    this.currentTab='electronicAccessControlRecord';
    // this.$refs.list.onRefresh();
    this.roomIP=this.$route.query.ip;
    this.location=this.$route.query.location;
    this.stateStr=this.$route.query.stateStr;
    this.firmwareVersion=this.$route.query.firmwareVersion;
    this.mac=this.$route.query.mac;
    this.type=this.$route.query.type;
  },
  methods: {
     toggleTab: function (tab) {
        this.currentTab = tab; // tab 为当前触发标签页的组件名
      },
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }
  .lock-log-tit{
    font-size: 16px;
    color: #303133;
    line-height: 30px;
  }
  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30BAC1;
        border-bottom: 2px solid #30BAC1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
}
</style>
